function About() {
  return (
    <div className="text-white">
      <div className="container">
        <div className="bg-white/5 border border-white/10 rounded-md px-10 py-7 text-2xl tracking-wide font-medium">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos,
          dignissimos quasi, mollitia ex enim eveniet quam debitis rerum iusto
          quidem pariatur amet voluptatem! Quam labore minus repudiandae maiores
          harum dolor. Facilis dolor alias sed nesciunt totam eveniet officiis
          autem nostrum quia, explicabo ratione illum numquam sit maiores
          tempora facere, quasi quis ullam tenetur omnis ex quos sint veritatis
          earum. Expedita! :D
        </div>
      </div>
    </div>
  );
}

export default About;
